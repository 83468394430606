import React from 'react';
import { withRouter } from 'react-router-dom';

import logo from '../../assets/logo.png';
import logo2 from '../../assets/logo2.png';
import './header.styles.scss';

const Header = ({match, history}) => {
    return(
        <div className='navBar'>
            <img className='logo' src={logo} alt='Logo'/>
            <div className='menu-bar'>
                <div onClick={() => history.push(`${match.url}`)}>
                    <p className='menu-item'>Gallery</p>
                </div>
                <div onClick={() => history.push(`${match.url}about`)}>
                    <p className='menu-item'>About</p>
                </div>
                <div onClick={() => history.push(`${match.url}contact`)}>
                    <p className='menu-item'>Contact</p>
                </div>
                <div onClick={() => history.push(`${match.url}control`)}>
                    <p className='menu-item-control'>System Control</p>
                </div>
            </div>

            <img className='logo' src={logo2} alt='Logo'/>
        </div>
    );
}

export default withRouter(Header);