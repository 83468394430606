import React from 'react';

// import Header from '../../components/Header/header.component';
import Gallery from '../../components/Gallery/gallery.component';
import './gallery.styles.scss';

function GalleryPage() {
    return (
        <div className='gallery-container'>
            {/* <Header /> */}
            <Gallery />
        </div>
    )
}

export default GalleryPage;