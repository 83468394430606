import React from 'react';
import './contact.styles.scss';

import map from '../../assets/map2.png';

function ContactPage() {
    return(
        <div className='contact-text-view'>
            <img className='map' src={map} alt='Map'/>
            <div className='contact-text'>
                <p className='contact-text-elements'>Unit 1</p>
                <p className='contact-text-elements'>Liddal Way Industrial Estate</p>
                <p className='contact-text-elements'>Horton Road</p>
                <p className='contact-text-elements'>Middlesex, UB7 8PG</p>
                <p className='contact-text-elements'></p>
                <p className='contact-text-elements'>info@systemsound.com</p>
                <p className='contact-text-elements'>+44 (0)1895 432 995</p>
            </div>
        </div>
    );
}

export default ContactPage;