import React from 'react';
import './control.styles.scss';

function ContactPage() {
    return(
        <div className='contact-text-view'>
            <div className='contact-text'>
                <p className='contact-text-elements'>{`THEATRE ELECTRONICS & DEVICES`}</p>
                <p className='contact-text-elements'>System Control specialises in theatre and event electronics.
                We build and operate the complex control systems behind the moving sets of some of the largest of London's West End Musicals.
                We also design and build excellent bespoke quiz systems and the simple, handy gadgets such as cue lights and speaker
                 traffic lights that make an event producer’s job that much easier.</p>
                <p className='contact-text-elements'></p>
                <p className='contact-text-elements'>info@systemcontrol.com</p>
                <p className='contact-text-elements'>+44 (0)1895 432 995</p>
            </div>
        </div>
    );
}

export default ContactPage;