import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import posed, { PoseGroup } from 'react-pose';

import Header from './components/Header/header.component';
import GalleryPage from './pages/Gallery/gallery.component';
import AboutPage from './pages/About/about.component';
import ContactPage from './pages/Contact/contact.component';
import ControlPage from './pages/Control/control.component';

import './App.css';

const RoutesContainer = posed.div({
  enter: { opacity: 1, delay: 100},
  exit: { opacity: 0 }
});

function App({location}) {
  // document.title = "System Sound"
  return (
    <div className="App">
      <Header />
      <PoseGroup>
        <RoutesContainer key={location.pathname}>
          <Switch location={location}>
            <Route exact path='/' component={GalleryPage} />
            <Route path='/about' component={AboutPage} />
            <Route path='/contact' component={ContactPage} />
            <Route path='/control' component={ControlPage} />
          </Switch>
        </RoutesContainer>
      </PoseGroup>
    </div>
  );
}

export default withRouter(App);
