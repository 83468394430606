import React from 'react';
import './about.styles.scss';

function AboutPage() {
    return (
        <div className='about-text-view'>
            <div className='about-text'>
                <p className='about-text-elements'>System Sound are dedicated audio specialists.</p>
                <p className='about-text-elements'>Established in 1986 and based in West London, we are one of the UK's leading sound designers and equipment rental experts with a huge breadth of experience in Britain and around the world.</p>
                <p className='about-text-elements'>We have provided top of the line systems in venues as diverse as Hyde Park and The 100 Club in London and in exciting cities like LA, Phoenix, New York, Toronto, Penang, Tokyo and most of Europe.</p>
                <p className='about-text-elements'>Music is hugely important to us and we have a policy of simply providing the best that is possible. We are selective in the shows that we produce and we aim at the more creatively driven events.</p>
            </div>
        </div>
    );
}

export default AboutPage;