import React from 'react';
import ImageGallery from 'react-image-gallery';

import './gallery.styles.scss'

const images = [
  {
    original: 'http://systemsound.com/static/media/gallery/large/1.jpg',
  },
  {
    original: 'http://systemsound.com/static/media/gallery/large/2.jpg',
  },
  {
    original: 'http://systemsound.com/static/media/gallery/large/3.jpg',
  },
  {
    original: 'http://systemsound.com/static/media/gallery/large/4.jpg',
  },
  {
    original: 'http://systemsound.com/static/media/gallery/large/5.jpg',
  },
  {
    original: 'http://systemsound.com/static/media/gallery/large/6.jpg',
  },
  {
    original: 'http://systemsound.com/static/media/gallery/large/8.jpg',
  },
  {
    original: 'http://systemsound.com/static/media/gallery/large/9.jpg',
  },
  {
    original: 'http://systemsound.com/static/media/gallery/large/10.jpg',
  },
  {
    original: 'http://systemsound.com/static/media/gallery/large/11.jpg',
  },
];
 
class Gallery extends React.Component {
  render() {
      return <ImageGallery
            items={images}
            autoPlay={true}
            slideInterval={6000}
            slideDuration={1000}
            showNav={false}
            showFullscreenButton={false}
            showThumbnails={false}
            showPlayButton={false}
            disableKeyDown={true}
            lazyLoad={true}
        />;
  }
}

export default Gallery;